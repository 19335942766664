<template>
  <div class="profilePicture">
    <div class="profilePicture__title">
      {{ $t('profilePicture.title') }}
    </div>

    <figure
      class="profilePicture__imageSelector"
      v-on:click="onFileOpenClick"
    >
      <img
        v-bind:src="previewImage || `${member.image}?w=140&q=80` || $getDefaultProfileIcon"
        class="profilePicture__imageSelectorImage"
        v-on:error="getProfileImageFallback"
      />

      <figcaption class="profilePicture__imageSelectorLabel">
        {{ $t('profilePicture.label') }}
      </figcaption>

      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        ref="imageInput"
        class="profilePicture__imageSelectorInput"
        v-on:change="onImageSelected"
      />
    </figure>

    <p class="profilePicture__note">
      {{ $t('profilePicture.note') }}
    </p>

    <base-button
      modifiers="primary"
      loading-text="Opslaan"
      v-bind:disabled="!selectedImage"
      v-bind:show-spinner="isSaving"
      v-on:click.prevent="onSaveClick"
    >
      {{ $t('profilePicture.saveButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BaseButton from '@/components/BaseButton';
import profileMixin from '@/mixins/profile';

export default {
  components: {
    BaseButton,
  },

  mixins: [profileMixin],

  data() {
    return {
      previewImage: null,
      selectedImage: null,
      isSaving: false,
    };
  },

  computed: {
    ...mapState('member', ['member']),
  },

  methods: {
    ...mapActions({
      updateProfileImage: 'member/updateProfileImage',
    }),

    onFileOpenClick() {
      this.$refs.imageInput.click();
    },

    onImageSelected(event) {
      const files = event.target.files;

      if (files.length) {
        const image = files[0];
        const reader = new FileReader();

        this.selectedImage = image;

        reader.onload = e => {
          this.previewImage = e.target.result;
        }

        reader.readAsDataURL(image);
      }
    },

    onSaveClick() {
      const formData = new FormData();
      formData.append('file', this.selectedImage);

      this.isSaving = true;
      this.updateProfileImage(formData)
        .then(() => {
          this.isSaving = false;
          this.selectedImage = null;
        });
    },

    getProfileImageFallback(event) {
      event.target.src = this.$getDefaultProfileIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profilePicture__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profilePicture__imageSelector {
  display: flex;
  align-items: center;
  margin: 0 0 rem(16px) 0;
  cursor: pointer;
}

.profilePicture__imageSelectorImage {
  margin: 0 rem(16px) 0 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.profilePicture__imageSelectorLabel {
  @include small;
  text-decoration: underline;
  color: $color-green;
}

.profilePicture__imageSelectorInput {
  display: none;
}

.profilePicture__note {
  @include note;
  margin: 0 0 rem(24px) 0;
  max-width: 44ch;
  color: $color-grey;
}
</style>
